var site = site || {};
var generic = generic || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
(function ($) {
  Drupal.behaviors.gnavAccountV1 = {
    attach: function (context) {
      var $template = $('.js-gnav-account', context);
      var $accountContent = $('.js-gnav-account-content', $template);
      var $accountLinkTrigger = $('.js-gnav-util-trigger-account', $template);
      var $accountLinkLabel = $('.js-gnav-util-trigger-label', $template);
      var $accountLinkLabelWelcome = $('.js-gnav-util-trigger-label-welcome', $template);
      var $closeOverlay = $('.js-gnav-section-trigger-close', $template);
      var $errorFacebookTerms = $('.js-fb-disclaimer-error', $template);
      var redirectUrl = $('.js-redirect-url', $template).attr('data-url');
      var $container = $(
        '.js-gnav-account-content-container-forms-signin-container-required',
        $template
      );
      var $html = $('html');
      var $signinEmailAddressNode = $accountContent.find('.js-login-email', $template);
      var $passwordResetEmailNode = $('.js-password_reset_send__overlay_EMAIL_ADDRESS', $template);
      var $forgotPasswordLink = $('.js-forgot-password', $template);
      var $errors = $('.js-error-messages', $template);
      var $passwordResetForm = $('.js-sign-in-page__overlay_password_reset_send form', $template);
      var hash = generic.Hash();
      var param;
      var emailAddress;
      var token;
      var welcomeText;
      var fullName = site.userInfoCookie.getValue('full_name');
      var firstName =
        site.userInfoCookie.getValue('first_name') || (fullName && fullName.replace(/\+/gu, ' ')) || '';
      var greetingText = $accountLinkLabelWelcome.text();
      const emailMissingErrorMsg = $('.js-email-address-blank-error', $template).html();
      const pwdMissingErrorMsg = $('.js-password-blank-error', $template).html();

      if (parseInt(site.userInfoCookie.getValue('signed_in')) === 0) {
        $accountContent.removeClass('is-signed-in');
        $accountLinkLabel.removeClass('is-signed-in');
      } else if (
        parseInt(site.userInfoCookie.getValue('signed_in')) === 1 &&
        greetingText.indexOf(firstName) <= 0
      ) {
        $accountContent.addClass('is-signed-in');
        $accountLinkLabel.addClass('is-signed-in');
        welcomeText = $accountLinkLabelWelcome.text() + firstName;

        $accountLinkLabelWelcome.html(welcomeText);
      }

      $closeOverlay.once().on('click', function () {
        $html.toggleClass('active-utility-overlay', false);
        if ($errorFacebookTerms.length > 0 && !$errorFacebookTerms.hasClass('hidden')) {
          $errorFacebookTerms.addClass('hidden');
        }
      });

      if ($passwordResetEmailNode.length === 1) {
        $signinEmailAddressNode.once().on('change', function () {
          $passwordResetEmailNode.val($(this).val());
        });
      }

      $forgotPasswordLink.on('click', function (e) {
        e.preventDefault();
        if ($passwordResetEmailNode.length === 1) {
          emailAddress = $signinEmailAddressNode.val();
          if (emailAddress.length < 1) {
            $errors.find('.js-password_error_message').removeClass('hidden');
            $errors.find('.js-password_reset_send_msg').addClass('hidden');

            return;
          }

          $passwordResetEmailNode.val(emailAddress);
          param = hash.queryToJson($passwordResetForm.serialize());
          Object.entries(param).forEach(([key, value]) => {
            param[key] = decodeURIComponent(value);
          });
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [param],
            onBoth: function (r) {
              var messages = r.getMessages();

              if (messages) {
                messages.forEach(function (message) {
                  if (message.key === 'request_sent') {
                    $errors.find('.js-password_reset_send_msg').removeClass('hidden');
                    $errors.find('.js-password_error_message').addClass('hidden');
                  } else {
                    $errors.find('.js-password_error_message').removeClass('hidden');
                    $errors.find('.js-password_reset_send_msg').addClass('hidden');
                  }
                });
              }
            }
          });
        }
      });

      $accountLinkTrigger
        .off('click.siteHeader touchstart.siteHeader')
        .on('click.siteHeader touchstart.siteHeader', function () {
          var disableGnav = Drupal.settings.common.disableGnav || false;
          var signinUrl = '/account/signin.tmpl';
          var accountUrl = '/account/index.tmpl';

          if (disableGnav && parseInt(site.userInfoCookie.getValue('signed_in'), 10) !== 1) {
            window.location = signinUrl;
          } else if (disableGnav && parseInt(site.userInfoCookie.getValue('signed_in'), 10) === 1) {
            window.location = accountUrl;
          }
          $html.toggleClass('active-utility-overlay', true);
          // Remove active-gnav class from html
          $html.toggleClass('active-gnav', false);
          $(document).trigger('loadFacebookScript');
        });

      // Close utility overlay when moving the mouse out or clicking outside of it:
      $html.once().on(
        'click mouseout mouseover',
        _.debounce(function (event) {
          // Avoid mouseout on hover input field
          if (event.type !== 'click') {
            if (
              event.relatedTarget === null ||
              event.relatedTarget.nodeName.toLowerCase() === 'input'
            ) {
              return;
            }
          }

          if (
            $html.hasClass('active-utility-overlay') &&
            ((event.type === 'mouseout' && !event.relatedTarget) ||
              !$(event.target).parents('.site-header-formatter__header').length)
          ) {
            $html.toggleClass('active-utility-overlay', false);
            $(document).trigger('check_reset_gnav_radio_buttom');
          }
        }, 300)
      );

      $accountContent.each(function () {
        var $block = $(this);
        var $toggleSigninTrigger = $('.js-gnav-account-content-header-title-toggle-signin', $block);
        var $toggleRegisterTrigger = $(
          '.js-gnav-account-content-header-title-toggle-register',
          $block
        );
        var $registerForm = $(
          '.js-gnav-account-content-container-forms-register-container-form',
          $block
        );
        var $signInForm = $(
          '.js-gnav-account-content-container-forms-signin-container-form',
          $block
        );
        var $inputToken = $(
          '.js-gnav-account-content-container-forms-register-container-form-token',
          $registerForm
        );
        var $userName = $('.js-gnav-account-content-container-forms-account-links-name', $block);

        $userName.html(firstName);
        $inputToken.val(token);

        $registerForm.once().on('submit', function (event) {
          var error = false;

          error = validateInputsRequired($(this));
          if (error) {
            event.preventDefault();
          }
        });

        $signInForm.once().on('submit', function (event) {
          var error = false;

          error = validateInputsRequired($(this));
          if (error) {
            event.preventDefault();
          }
        });
        if (redirectUrl) {
          $('.js-gnav-account-content-header-title-toggle-register', $template).one(
            'click',
            function (e) {
              e.preventDefault();
              window.location.assign(redirectUrl);
            }
          );
        }

        $toggleSigninTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('register-active');
          $block.addClass('signin-active');
        });

        $toggleRegisterTrigger.once().on('click', function (event) {
          event.preventDefault();
          $block.removeClass('signin-active');
          $block.addClass('register-active');
        });
      });

      function validateInputsRequired($element) {
        var error = false;

        if ($element.length === 0) {
          return true;
        }
        emailMissingErrorMsg && pwdMissingErrorMsg ? $container.html('') : '';
        $element.find('input').each(function () {
          var $this = $(this);

          if ($this.hasClass('required')) {
            if (!$this.val() || ($this.attr('type') === 'checkbox' && !$this.prop('checked'))) {
              $this.prop('name') === 'EMAIL_ADDRESS' && emailMissingErrorMsg
                ? $container.append(emailMissingErrorMsg).addClass('error-text')
                : '';
              $this.prop('name') === 'PASSWORD' && pwdMissingErrorMsg
                ? $container.append(pwdMissingErrorMsg).addClass('error-text')
                : '';
              error = true;
              $this.addClass('error');
            } else {
              $this.removeClass('error');
            }
          }
        });

        return error;
      }
    }
  };
})(jQuery);
